<template>
  <div class="container-recommendedIteright">
    <div class="recommended-item-img" v-if="overlayDataList !== null">
      <div v-for="(item,index) in overlayDataList" :key="index">
        <div class="recommended-item-img-top">

          <div class="love" :class="[!item.status?'love':start]" @click="tabImgColor(item.id,index,item.status)"
            v-if="hide"></div>
          <!-- <div :style="{background:'url('+item.hostImage+')' } " class="forBackgroundImage"
            @click="searchDeatile(item.id)"></div> -->
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
                  <!-- <a href="javascript:;" @click="searchDeatile(item.id)"> -->
                  <img :src="item.hostImage+imgSize(945)" alt="">
                  <!-- </a> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 3 && item.itemAuctionWayId === 7"
            style="background: #CDA156;">成交</div>
          <div class="status-item" v-if="item.itemAuctionStatus === 4 && item.itemAuctionWayId === 7"
            style="background: #BFC3C4;color: #666666;">流拍
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 5 && item.itemAuctionWayId === 7"
            style="background: #BFC3C4;color: #666666;">撤拍
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 2 && item.itemAuctionWayId === 7">直播中</div>
          <div class="status-item" style="background: #CDA156;"
            v-if="item.itemAuctionStatus === 1 && item.itemAuctionWayId === 7">待拍</div>
        </div>
        <div class="recommended-item-img-bottom">
          <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
            Lot&nbsp;{{item.lot}}：{{item.title}}
          </router-link>
          <!-- <a href="javascript:;" :title="item.title"
            @click="searchDeatile(item.id)">Lot&nbsp;{{item.lot}}：{{item.title}}</a> -->
          <router-link target="_blank"
            :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.auctionHouseId}}">
            {{item.auctionHouseName}}
          </router-link>
          <!-- <a href="javascript:;" @click="auctionHouseName(item.auctionHouseId)">{{item.auctionHouseName}}</a> -->
          <p>
            <span>{{item.auctionTime}}</span>
            <span>|</span>
            <span>北京时间</span>
          </p>
          <p v-if="item.nowPrice === ''">起拍价格：{{item.initialPrice}}&nbsp;{{item.currency}}</p>
          <p v-else>当前价格：{{item.nowPrice}}&nbsp;{{item.currency}}</p>
        </div>
      </div>
    </div>
    <div class="search-emty" v-else>
      <img src="../../../assets/images/22.png" alt="">
      <span>此分类暂无数据！！</span>
    </div>
    <Login ref="login" />
  </div>
</template>
<script>
  import Login from '../../../components/login.vue'
  import {
    userCollect
  } from '../../../api/index'
  export default {
    name: 'recommendedIteright',
    components: {
      Login
    },
    props: {
      overlayDataList: {
        type: Array,
        default: () => {}
      },
      page: {
        type: Number
      },
      pageSize: {
        type: Number
      }
    },
    data() {
      return {
        recentBrowseListValue: [],
        start: 'active',
        hide: true
      }
    },
    created() {
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      tabImgColor(id, index, status) {
        if (!localStorage.getItem('accessToken')) {
          // this.$message.error('请登录')
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        } else {
          if (status === true) {
            this.$message.error('取消收藏')
          } else {
            this.$message.success('收藏成功')
          }
          this.overlayDataList[index].status = !this.overlayDataList[index].status

          this.userCollect(id)
        }
      },
      // 点击收藏
      async userCollect(id) {
        const res = await userCollect({
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          itemid: id
        })
        if (res.code === 0) {
          // this.$message.success('收藏成功')
        } else {
          this.$message.error('收藏失败')
        }
      },
      // 点击跳转产品详情
      searchDeatile(id) {
        sessionStorage.setItem('currentPage6', this.page)
        sessionStorage.setItem('select6', this.pageSize)
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id
          }
        })
      },
      auctionHouseName(id) {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-recommendedIteright {
    // display: flex;
    width: 1728px;

    .recommended-item-img {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      div:hover {
        box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.1);
      }

      div {
        width: 315px;
        // height: 417px;
        margin: 20px;
        // display: inline-block;
        box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.05);
        background: #fff;

        .recommended-item-img-top {
          width: 100%;
          height: 315px;
          margin: 0;
          position: relative;
          overflow: hidden;

          .status-item {
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            background: #d10000;
            color: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
          }

          .forBackgroundImage {
            background-size: 70% !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            width: 100%;
            // z-index: -1;
            margin: 0;
            opacity: 1;
            height: 100%;
          }

          .col-md-3 {
            width: 100%;
            margin: 0;
            border: none;
            height: none;

            .thumb-container {
              width: 100%;
              // height: 317px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }

          .love:hover {
            background: url("../../../assets/images/17.png") no-repeat;
            background-size: 40px;
          }

          .love {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 10px;
            right: 10px;
            margin: 0;
            background: url("../../../assets/images/9.png") no-repeat;
            background-size: 40px;
            border: transparent;
            border-radius: 50%;
            z-index: 100;
          }

          .active {
            background: url("../../../assets/images/17.png") no-repeat !important;
            background-size: 40px !important;
            position: absolute;
            width: 40px;
            height: 40px;
            top: 10px;
            right: 10px;
            margin: 0;
            background-size: 40px;
            border: transparent;
            border-radius: 50%;
          }
        }

        .recommended-item-img-bottom {
          width: 100%;
          // height: 230px;
          margin: 0;
          box-sizing: border-box;
          padding: 0 28px;
          text-align: left;
          padding-top: 20px;

          p {
            margin-bottom: 0;
          }

          a:nth-child(1) {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /*2行末尾显示点点点*/
            padding-bottom: 10px;
            height: 63px;

            &:hover {
              text-decoration: underline;
            }
          }

          a:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #2c3e50;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            padding-bottom: 10px;

            &:hover {
              text-decoration: underline;
            }
          }

          p:nth-child(3) {
            color: #666;
            font-size: 18px;
            padding-bottom: 10px;

            span:nth-child(2) {
              padding: 0 10px;
            }
          }

          p:nth-child(4) {
            font-weight: 500;
            font-size: 18px;
            color: #333;
            padding-bottom: 30px;
          }
        }
      }
    }

    .search-emty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 69px;

      img {
        width: 169px;
      }

      span {
        padding-top: 69px;
        color: #cda156;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
</style>
