<template>
  <div class="container-recommendedItemall">
    <div class="auctionhome-header">
      <p>{{ total }}个拍品</p>
      <div class="pagination-middle">
        <div id="components-pagination-demo-mini">
          <a-pagination
            size="small"
            :total="total"
            @change="onChange"
            v-model="overlayData.page"
            :pageSize="overlayData.pageSize"
          />
        </div>
      </div>
      <div class="auctionhome-header-right">
        <span>每页显示</span>
        <a-select
          default-value="40"
          style="width: 120px"
          v-model="value2"
          @change="handleChange"
        >
          <a-select-option
            :value="item.num"
            v-for="(item, index) in pageSizeNum"
            :key="index"
          >
            {{ item.num }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="search-list">
      <!-- 左侧组件 -->
      <RecommendedItemchoose
        v-on:emitData="showMsgfromChild"
        :attributeListA="attributeListA"
        :brandAuthorIdA="brandAuthorIdA"
        :stylePeriodIdA="stylePeriodIdA"
        :materialsCraftIdA="materialsCraftIdA"
        :placeSourceIdA="placeSourceIdA"
        :auctionHouseIdA="auctionHouseIdA"
        :twoClassifyIdB="twoClassifyIdB"
        :attributeListShow="attributeListShow"
        :auctionHouseData="auctionHouseData"
        :auctionHouseIdSearch="auctionHouseIdSearch"
      />
      <!-- 右侧组件 -->
      <RecommendedIteright
        :overlayDataList="overlayDataList"
        :page="overlayData.page"
        :pageSize="overlayData.pageSize"
      />
    </div>
    <div class="auctionhome-header">
      <p>{{ total }}个拍品</p>
      <div class="pagination-middle" @click="goTop()">
        <div id="components-pagination-demo-mini">
          <a-pagination
            size="small"
            :total="total"
            @change="onChange"
            v-model="overlayData.page"
            :pageSize="overlayData.pageSize"
          />
        </div>
      </div>
      <div class="auctionhome-header-right">
        <span>每页显示</span>
        <a-select
          default-value="40"
          style="width: 120px"
          v-model="value2"
          @change="handleChange"
        >
          <a-select-option
            :value="item.num"
            v-for="(item, index) in pageSizeNum"
            :key="index"
          >
            {{ item.num }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </div>
</template>
<script>
import RecommendedItemchoose from './components/RecommendedItemchoose.vue'
import RecommendedIteright from './components/RecommendedIteright.vue'
import { classifyItemList, classifyItemAttribute } from '../../api/index'
export default {
  name: 'RecommendedItemall',
  components: {
    RecommendedItemchoose,
    RecommendedIteright
  },
  data () {
    return {
      isChoose: false,
      value: '',
      overlayData: {
        id: null,
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        userId: 0,
        page: 1,
        pageSize: 40,
        classifyId: '',
        twoClassifyId: ''
      },
      attributeListA: {},
      brandAuthorIdA: {},
      stylePeriodIdA: {},
      materialsCraftIdA: {},
      placeSourceIdA: {},
      auctionHouseIdA: {},
      auctionHouseData: {},
      classifyIdA: {},
      twoClassifyIdB: {},
      auctionHouseIdSearch: {},
      total: 0,
      overlayDataList: [],
      attributeListShow: null,
      pageSizeNum: [
        {
          id: 1,
          num: 40
        },
        {
          id: 2,
          num: 80
        },
        {
          id: 3,
          num: 120
        }
      ],
      value2: 40,
      top: 0
    }
  },
  // beforeRouteUpdate (to, from, next) {
  //   if (to.query && from.query && to.query.overlatId !== from.query.overlatId) {
  //
  //     this.classifyItemList()
  //   }
  //   next()
  // },
  // beforeRouteLeave (to, from, next) {
  //   if (to.name === 'Productdetails') {
  //     sessionStorage.setItem('select3', this.overlayData.pageSize)
  //     sessionStorage.setItem('page3', this.overlayData.page)
  //   }
  //   next()
  // },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     console.log(from)
  //     if (from.name !== 'Productdetails') {
  //       vm.overlayData.page = 1
  //       vm.overlayData.pageSize = 40
  //     } else {
  //       vm.overlayData.page = Number(sessionStorage.getItem('page3')) || 1
  //       vm.overlayData.pageSize = Number(sessionStorage.getItem('select3')) || 40
  //       this.value2 = Number(sessionStorage.getItem('select3')) || 40
  //     }
  //   })
  // },
  watch: {
    $route () {
      this.overlayData.page = 1
      this.overlayData.pageSize = 40
      this.value2 = 40
      this.overlayData.id = Number(this.$route.query.overlatId)
      this.overlayData.auctionWayId = ''
      this.overlayData.brandAuthorId = ''
      this.overlayData.stylePeriodId = ''
      this.overlayData.materialsCraftId = ''
      this.overlayData.placeSourceId = ''
      this.overlayData.auctionHouseId = ''
      this.classifyItemList()
      this.classifyItemAttribute()
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed () {
    sessionStorage.removeItem('msgInfo15')
  },
  beforeRouteLeave (to, from, next) {
    if (to.path === '/auctionhome') {
      sessionStorage.removeItem('msgInfo')
    }
    next()
  },
  created () {
    this.overlayData.id = Number(this.$route.query.overlatId)
    // this.overlayData.page = Number(sessionStorage.getItem('page2')) || 1
    if (sessionStorage.getItem('msgInfo15')) {
      // 如果有就读取缓存里面的数据
      this.overlayData.page =
        Number(sessionStorage.getItem('currentPage6')) || 1
      this.overlayData.pageSize =
        Number(sessionStorage.getItem('select6')) || 40
      this.value2 = Number(sessionStorage.getItem('select6')) || 40
    } else {
      // 这个主要是从其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem('currentPage6')
      sessionStorage.removeItem('select6')
      this.overlayData.page = 1
      this.overlayData.pageSize = 40
      this.value2 = 40
    }
    this.attributeListShowF()
    this.classifyItemList()
    this.classifyItemAttribute()
  },

  methods: {
    toggePay () {
      this.isChoose = !this.isChoose
    },
    handleChange (value) {
      this.overlayData.page = 1
      this.overlayData.pageSize = value
      this.classifyItemList()
    },
    handleScroll (e) {
      const scrolltop = e.target.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    goTop () {
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = this.top -= 50
        if (this.top <= 0) {
          clearInterval(timeTop)
        }
      }, 0)
    },
    // 获取页面滚动高度
    scrollHandle (e) {
      this.top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
    },
    // 更新
    reload () {
      var timer = setTimeout(() => {
        this.reload()
        clearInterval(timer)
      }, 500)
    },
    // 子组件的参数
    showMsgfromChild (data) {
      this.overlayData.auctionWayId = data.auctionWayId
      this.overlayData.brandAuthorId = data.brandAuthorId
      this.overlayData.stylePeriodId = data.stylePeriodId
      this.overlayData.materialsCraftId = data.materialsCraftId
      this.overlayData.placeSourceId = data.placeSourceId
      this.overlayData.auctionHouseId = data.auctionHouseId
      this.overlayData.classifyId = data.classifyId
      this.overlayData.twoClassifyId = data.twoClassifyId
      this.overlayData.page = data.page
      this.classifyItemList()
    },
    onChange (page) {
      this.overlayData.page = page
      sessionStorage.setItem('page2', this.overlayData.page)
      this.classifyItemList()
    },
    attributeListShowF () {
      if (
        this.$route.query.parentId === 0 ||
        this.$route.query.parentId === '0'
      ) {
        this.attributeListShow = true
      } else {
        this.attributeListShow = false
      }
    },
    async classifyItemAttribute () {
      const res = await classifyItemAttribute({
        classifyId: Number(this.$route.query.overlatId)
      })
      if (res.code === 0) {
        this.attributeListA = res.data.list[0]
        this.brandAuthorIdA = res.data.list[1]
        this.stylePeriodIdA = res.data.list[2]
        this.materialsCraftIdA = res.data.list[3]
        this.placeSourceIdA = res.data.list[4]
        this.auctionHouseIdA = res.data.list[5]
        this.auctionHouseData = res.data.list[6]
        if (this.auctionHouseIdSearch.valueAttribute !== undefined) {
          this.auctionHouseIdSearch = res.data.list[6]
        }
        this.attributeListShowF()
        // this.classifyIdA = res.data.list[6]
        // this.twoClassifyIdB = res.data.list[6]
      }
    },
    async classifyItemList () {
      const res = await classifyItemList(this.overlayData)
      if (res.code === 0) {
        this.overlayDataList = res.data.list

        this.total = res.data.count
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container-recommendedItemall {
  /deep/ .ant-pagination-item-active {
    border: none;
  }
  #components-pagination-demo-mini .ant-pagination:not(:last-child) {
    margin-bottom: 24px;
  }
  /deep/ .ant-select {
    width: 80px !important;
  }
  /deep/ .ant-pagination-item-active a {
    color: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #cda156;
  }
  /deep/ .ant-pagination-item {
    font-size: 17px;
  }
  /deep/ .ant-pagination-item-active a {
    color: #fff;
    background: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #000;
  }
  /deep/ .ant-pagination-item {
    border: 1px solid #ccc !important;
    margin: 0px 5px;
    a {
      font-size: 15px;
    }
  }
  /deep/ .ant-pagination-prev {
    border: 1px solid #ccc !important;
    margin-right: 5px;
  }
  /deep/ .ant-pagination-next {
    border: 1px solid #ccc !important;
    margin-left: 5px;
  }
  /deep/ .anticon {
    vertical-align: 0;
  }
  /deep/ .ant-pagination-item-active {
    background: #cda156;
  }
  .auctionhome-header {
    height: 70px;
    align-items: center;
    display: flex;
    margin: 0 100px;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    p:nth-child(1) {
      font-size: 20px;
      font-weight: 400;
      color: #333;
      margin-bottom: 0;
    }
    .auctionhome-header-right {
      display: flex;
      align-items: center;

      span {
        font-size: 18.5px;
        color: #333;
        font-weight: 400;
        padding-right: 10px;
      }

      p:nth-child(2) {
        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
          padding-right: 10px;
        }
        a {
          color: #333;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  .search-list {
    display: flex;
  }
  .pagination {
    margin-bottom: 60px;
    display: flex;
    #components-pagination-demo-mini {
      flex: 1;
      text-align: right;
      padding-right: 102px;
    }
  }
}
</style>
